@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?fcc6b3ac9cfb853a4e797f2ed3dfa4bf") format("truetype"),
url("./flaticon.woff?fcc6b3ac9cfb853a4e797f2ed3dfa4bf") format("woff"),
url("./flaticon.woff2?fcc6b3ac9cfb853a4e797f2ed3dfa4bf") format("woff2"),
url("./flaticon.eot?fcc6b3ac9cfb853a4e797f2ed3dfa4bf#iefix") format("embedded-opentype"),
url("./flaticon.svg?fcc6b3ac9cfb853a4e797f2ed3dfa4bf#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-diamond:before {
    content: "\f101";
}
.flaticon-reload:before {
    content: "\f102";
}
.flaticon-home:before {
    content: "\f103";
}
.flaticon-wallet:before {
    content: "\f104";
}
.flaticon-bitcoin:before {
    content: "\f105";
}
.flaticon-bar-chart:before {
    content: "\f106";
}
.flaticon-chart:before {
    content: "\f107";
}
.flaticon-bar-chart-1:before {
    content: "\f108";
}
.flaticon-time:before {
    content: "\f109";
}
.flaticon-settings:before {
    content: "\f10a";
}
.flaticon-headphones:before {
    content: "\f10b";
}
.flaticon-history:before {
    content: "\f10c";
}
.flaticon-bell:before {
    content: "\f10d";
}
.flaticon-sun:before {
    content: "\f10e";
}
.flaticon-sun-1:before {
    content: "\f10f";
}
.flaticon-moon:before {
    content: "\f110";
}
.flaticon-moon-1:before {
    content: "\f111";
}
.flaticon-currencies:before {
    content: "\f112";
}
.flaticon-money:before {
    content: "\f113";
}
.flaticon-money-1:before {
    content: "\f114";
}
.flaticon-money-bag:before {
    content: "\f115";
}
.flaticon-dollar:before {
    content: "\f116";
}
.flaticon-bank:before {
    content: "\f117";
}
.flaticon-phone:before {
    content: "\f118";
}
.flaticon-telephone:before {
    content: "\f119";
}
.flaticon-phone-call:before {
    content: "\f11a";
}
.flaticon-sign-in:before {
    content: "\f11b";
}
.flaticon-user:before {
    content: "\f11c";
}
.flaticon-login:before {
    content: "\f11d";
}
.flaticon-coding:before {
    content: "\f11e";
}
.flaticon-terminal:before {
    content: "\f11f";
}
.flaticon-coding-1:before {
    content: "\f120";
}
.flaticon-secure-shield:before {
    content: "\f121";
}
.flaticon-shield:before {
    content: "\f122";
}
.flaticon-secure:before {
    content: "\f123";
}
.flaticon-search:before {
    content: "\f124";
}
.flaticon-comment:before {
    content: "\f125";
}
.flaticon-conversation:before {
    content: "\f126";
}
.flaticon-chat:before {
    content: "\f127";
}
.flaticon-system:before {
    content: "\f128";
}
.flaticon-economic:before {
    content: "\f129";
}
.flaticon-email:before {
    content: "\f12a";
}
.flaticon-email-1:before {
    content: "\f12b";
}
.flaticon-padlock:before {
    content: "\f12c";
}
.flaticon-padlock-1:before {
    content: "\f12d";
}
.flaticon-lock:before {
    content: "\f12e";
}
